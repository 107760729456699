import { AxiosError, AxiosResponse } from 'axios';
import { ICustomerLead } from '../types/customer-lead-type';
import { IOperator } from '../types/operator-type';
import { Pagination } from '../types/pagination-type';
import { IReport } from '../types/report-type';
import { Network } from '../utils/Network';

interface IOperatorRequest {
	page: number;
	value?: string | null;
	size?: number;
	enabled?: boolean;
}

interface IOperatorService {
	getPaginatedOperatorsReport: (
		props: IOperatorRequest,
	) => Promise<AxiosResponse<Pagination<IReport[], 'data'>, AxiosError>>;
	setEnabledOperator(uuid: string, toggle: boolean): Promise<void>;
	updateEsitoLead(leadId: string, esito: string, note: string): Promise<void>;
	changePasswordFirstLogin(uuid: string, password: string, confirmPassword: string): Promise<void>;
	requestNewLeads(uuid: string, requestLead: number): Promise<void>;
	getOperatorInformation: (id: string) => Promise<AxiosResponse<IOperator, AxiosError>>;
	getRevisionsAgencies(
		id: number,
	): Promise<AxiosResponse<Pagination<IOperator[], 'data'>, AxiosError>>;
	getOutcome(): Promise<AxiosResponse<string[], AxiosError>>;
	assignAgency(leadId: string, agencyId: string): Promise<void>;
	getPaginatedOperators: (
		props: IOperatorRequest,
	) => Promise<AxiosResponse<Pagination<IOperator[], 'data'>, AxiosError>>;
	createOperator: (body: IOperator) => Promise<void>;
	getLeadsAgency: (
		props: IOperatorRequest,
	) => Promise<AxiosResponse<Pagination<ICustomerLead[], 'data'>, AxiosError>>;
}

const OperatorService: IOperatorService = {
	getPaginatedOperators: ({ page, value, size, enabled }) => {
		return Network.get(`/v1/api/operator`, {
			params: {
				page,
				size,
				value,
				enabled: enabled,
			},
		});
	},
	createOperator: (body) => {
		return Network.post(`/public/authentication/signup`, {
			username: body.username,
			password: body.password,
			roles: ['OPERATOR'],
		});
	},
	assignAgency: (leadId, agencyId) => {
		return Network.get(`/v1/api/leads/${leadId}/assign`, { params: { agencyId } });
	},
	getLeadsAgency: ({ page, size, value }) =>
		Network.get(`/v1/api/agency/leads`, {
			params: {
				page,
				size,
				value,
			},
		}),
	getOutcome: () => Network.get(`/v1/api/esiti`),
	getRevisionsAgencies: (id) =>
		Network.get(`/v1/api/agencies/${id}/history`, { params: { page: 0, size: 100 } }),
	getOperatorInformation: (id) => Network.get(`/v1/api/operator/${id}`),
	requestNewLeads: (uuid, credit) =>
		Network.post(`/v1/api/agency/${uuid}/request/lead`, { credit }),
	changePasswordFirstLogin: (uuid, password, confirmPassword) =>
		Network.put(`/v1/api/agency/${uuid}/changePassword`, { password, confirmPassword }),
	updateEsitoLead: (leadId, esito, note) =>
		Network.put(`/v1/api/esiti/${leadId}/update`, { esito, note }),
	setEnabledOperator: (uuid, toggle) => {
		return Network.get(`/v1/api/operator/${uuid}/enabled`, { params: { toggle } });
	},
	getPaginatedOperatorsReport: ({ page, value, size }) =>
		Network.get(`/v1/api/operator/report`, {
			params: {
				page,
				size,
				value,
			},
		}),
};

export default OperatorService;
