import { create } from 'zustand';

import { LOCAL_STORAGE_TOKEN_KEY } from '../common/constants';
import AuthenticationService from '../services/AuthenticationServices';
import { ILoginProps } from '../types/login-props-type';
import { IUser } from '../types/user-type';

interface IUserState {
	user: Partial<IUser> | undefined;
	setUser: (newUser: Partial<IUser>) => void;
	logout: () => void;
	login: (props: ILoginProps) => Promise<void>;
	retrieveUser: () => Promise<void>;
}

export const useUserStore = create<IUserState>()((set) => ({
	user: undefined,
	setUser: (newUser) => set({ user: newUser }),
	login: async ({ username, password }) => {
		const response = await AuthenticationService.login({
			username,
			password,
		});
		localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
		set({ user: response.data });
	},
	logout: async () => {
		await AuthenticationService.logout(localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY));
		set({ user: undefined });
		localStorage.clear();
	},
	retrieveUser: async () => {
		const jwtToken = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
		if (!jwtToken) {
			throw new Error('NO_JWT_PROVIDED');
		}
		const response = await AuthenticationService.retrieveCustomer(jwtToken);
		set({ user: response.data });
	},
}));
