import { RouteProps } from 'react-router-dom';
import { loginPagesMenu, unloggedPages } from '../menu';
import DefaultHeader from '../pages/_layout/_headers/DefaultHeader';

const headers: RouteProps[] = [
	{ path: loginPagesMenu.login.path, element: null },
	{ path: unloggedPages.page404.path, element: null },
	{
		path: `*`,
		element: <DefaultHeader />,
	},
];

export default headers;
