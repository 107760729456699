import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import contents from '../../routes/contentRoutes';

const PAGE_404 = lazy(() => import('../../pages/presentation/auth/Page404'));
const ContentRoutes = () => {
	return (
		<Routes>
			{contents.map((page) => (
				<>
					{!page.subRoutes ? (
						// eslint-disable-next-line react/jsx-props-no-spreading
						<Route key={page.id} {...page} />
					) : (
						<Route key={page.id} path={page.path} element={page.element}>
							{page.subRoutes.map((subPage) => (
								// eslint-disable-next-line react/jsx-props-no-spreading
								<Route key={subPage.id} {...subPage} />
							))}
							{/* <Route
								path='*'
								element={page.subRoutes.find((route) => route.index)?.element}
							/> */}
						</Route>
					)}
				</>
			))}
			<Route path='*' element={<PAGE_404 />} />
		</Routes>
	);
};

export default ContentRoutes;
