import packageInfo from '../../../../package.json';
import Footer from '../../../layout/Footer/Footer';

const DefaultFooter = () => {
	return (
		// <Footer>
		<></>
		// 	<div className='container-fluid'>
		// 		<div className='row'>
		// 			<div className='col'>
		// 				<code className='ps-3'>Team2Com v{packageInfo.version}</code>
		// 			</div>
		// 		</div>
		// 	</div>
		// </Footer>
	);
};

export default DefaultFooter;
