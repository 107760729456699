import axios from 'axios';

import {
	BASE_URL,
	DEFAULT_TIMEOUT_REQUESTS,
	LOCAL_STORAGE_TOKEN_KEY,
	PUBLIC,
} from '../common/constants';
import { generateTransactionID } from './helpers';

/**
 * Axios defaults
 */
axios.defaults.baseURL = BASE_URL;
axios.defaults.timeout = DEFAULT_TIMEOUT_REQUESTS;

// Headers
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common.Accept = 'application/json';
axios.defaults.headers.common.channel = 'WEB';

/**
 * Request Interceptor
 */
axios.interceptors.request.use(
	async (inputConfig) => {
		const config = inputConfig;

		config.headers.transactionId = generateTransactionID();

		// Check for and add the stored Auth Token to the header request
		let token: string | null = '';
		let publicKey: string | null = '';
		try {
			token = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
			publicKey = localStorage.getItem(PUBLIC);
		} catch (error) {
			/* Nothing */
		}
		if (publicKey) {
			return config;
		}
		if (token && config.headers) {
			config.headers.Authorization = `Bearer ${token}`;
		}

		return config;
	},
	(error) => {
		throw error;
	},
);

/**
 * Response Interceptor
 */
axios.interceptors.response.use(
	(res) => {
		// Status code isn't a success code - throw error
		if (!`${res.status}`.startsWith('2')) {
			throw res.data;
		}

		// Otherwise just return the data
		return res;
	},
	(error) => {
		// Pass the response from the API, rather than a status code
		if (error && error.response && error.response.data) {
			throw error.response.data;
		}
		throw error;
	},
);

export { axios as Network };
