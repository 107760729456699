import { AxiosError, AxiosResponse } from 'axios';
import { INotifications } from '../types/notifications-type';
import { Pagination } from '../types/pagination-type';
import { Network } from '../utils/Network';

interface INotificationService {
	countNewNotifications(): Promise<AxiosResponse<number, AxiosError>>;
	getPaginatedNotifications(
		page: number,
		size: number,
	): Promise<AxiosResponse<Pagination<INotifications[], 'data'>, AxiosError>>;
	changeStatusRead(): Promise<void>;
}

const NotificationService: INotificationService = {
	getPaginatedNotifications: (page, size) =>
		Network.get(`/v1/api/notifications`, {
			params: {
				page,
				size,
			},
		}),

	countNewNotifications: () => Network.get(`/v1/api/notifications/count`),
	changeStatusRead: () => Network.put(`/v1/api/notifications`),
};

export default NotificationService;
