import { create } from 'zustand';

import { DEFAULT_ITEMS_FOR_PAGE } from '../common/constants';
import NotificationService from '../services/NotificationService';
import { INotifications } from '../types/notifications-type';
import { Pagination } from '../types/pagination-type';

interface IGetAllNotificationRequest {
	page: number;
	size?: number;
}

interface INotificationsState {
	paginatedNotification: Pagination<INotifications[], 'data'> | undefined;
	loadingNotification: boolean;
	countNewNotification: number;
	getPaginatedNotifications: (
		request: IGetAllNotificationRequest,
	) => Promise<Pagination<INotifications[], 'data'> | null>;
	countNewNotifications: () => Promise<number>;
	changeStatusRead: () => Promise<void>;
}

export const useNotificationsStore = create<INotificationsState>()((set) => ({
	paginatedNotification: undefined,
	loadingNotification: false,
	countNewNotification: 0,
	getPaginatedNotifications: async ({ page, size }) => {
		set({
			loadingNotification: true,
		});
		const response = await NotificationService.getPaginatedNotifications(
			page,
			size || DEFAULT_ITEMS_FOR_PAGE,
		);
		set({
			loadingNotification: false,
			paginatedNotification: response.data,
		});
		return response.data;
	},
	countNewNotifications: async () => {
		const response = await NotificationService.countNewNotifications();
		set({
			countNewNotification: response.data,
		});
		return response.data;
	},
	changeStatusRead: async () => {
		await NotificationService.changeStatusRead();
		set({ countNewNotification: 0 });
	},
}));
