import { create } from 'zustand';

import { DEFAULT_ITEMS_FOR_PAGE } from '../common/constants';
import showNotification from '../components/extras/showNotification';
import AdminService from '../services/AdminService';
import AgencyService from '../services/AgencyService';
import OperatorService from '../services/OperatorService';
import { IEsito } from '../types/esiti-type';
import { IOperator } from '../types/operator-type';
import { Pagination } from '../types/pagination-type';
import { IReport } from '../types/report-type';
import { Shower } from '../components/icon/material-icons';

interface IOperatorRequest {
	page: number;
	value?: string | null;
	size?: number;
	enabled?: boolean;
}

interface IOperatorState {
	revisionsAgency: Pagination<IOperator[], 'data'> | undefined;
	paginatedOperators: Pagination<IOperator[], 'data'> | undefined;
	operatorInformation: IOperator | undefined;
	loadingOperator: boolean;
	outcomes: IEsito[] | undefined;
	paginatedOperatorsReport: Pagination<IReport[], 'data'> | undefined;
	showEnabled: boolean;
	getPaginatedOperators: (
		request: IOperatorRequest,
	) => Promise<Pagination<IOperator[], 'data'> | null>;
	createOperator: (body: IOperator) => Promise<void>;
	getOutcome: () => Promise<IEsito[]>;
	getRevisionHistory: (id: number) => Promise<Pagination<IOperator[], 'data'> | undefined>;
	getOperatorInformation: (id: string) => Promise<IOperator | undefined>;
	changePasswordFirstLogin(uuid: string, password: string, confermaPassword: string): Promise<void>;
	setEnabledOperator: (uuid: string, toggle: boolean) => Promise<void>;
	assignGroups: (groups: string[], operatorId: number) => Promise<void>;
	getPaginatedOperatorsReport: (
		request: IOperatorRequest,
	) => Promise<Pagination<IReport[], 'data'> | null>;
	setShowEnabled(value: boolean): void;
}

export const useOperatorStore = create<IOperatorState>()((set, get) => ({
	revisionsAgency: undefined,
	paginatedOperators: undefined,
	operatorInformation: undefined,
	loadingOperator: false,
	outcomes: undefined,
	paginatedOperatorsReport: undefined,
	showEnabled: false,
	getPaginatedOperators: async ({ page, value, size, enabled }) => {
		set({
			loadingOperator: true,
		});
		const response = await OperatorService.getPaginatedOperators({
			page,
			value: value || '',
			size: size || DEFAULT_ITEMS_FOR_PAGE,
			enabled: enabled,
		});
		set({
			loadingOperator: false,
			paginatedOperators: response.data,
		});
		return response.data;
	},
	createOperator: async (body) => {
		try {
			const response = await OperatorService.createOperator(body);
			showNotification('Successo', 'Operatore creato con successo', 'success');
			await get().getPaginatedOperators({
				page: 0,
				value: '',
				size: DEFAULT_ITEMS_FOR_PAGE,
				enabled: get().showEnabled,
			});
			return response;
		} catch (error) {
			showNotification('Errore', "Errore durante la creazione dell'operatore", 'danger');
			throw new Error("Errore durante la creazione dell'operatore");
		}
	},
	getOutcome: async () => {
		const response = await AgencyService.getOutcome();
		set({
			outcomes: response.data,
		});
		return response.data;
	},
	getRevisionHistory: async (id) => {
		const response = await OperatorService.getRevisionsAgencies(id);
		set({ revisionsAgency: response.data });
		return response.data;
	},
	getOperatorInformation: async (id) => {
		const response = await OperatorService.getOperatorInformation(id);
		set({ operatorInformation: response.data });
		return response.data;
	},
	changePasswordFirstLogin: async (uuid, password, confermaPassword) => {
		const response = await OperatorService.changePasswordFirstLogin(
			uuid,
			password,
			confermaPassword,
		);
		showNotification('Successo', 'Password cambiata correttamente, accedi nuovamente', 'success');
		return response;
	},
	setEnabledOperator: async (uuid, toggle) => {
		const response = await OperatorService.setEnabledOperator(uuid, toggle);
		await get().getPaginatedOperators({
			page: 0,
			value: '',
			size: DEFAULT_ITEMS_FOR_PAGE,
			enabled: get().showEnabled,
		});
		showNotification(
			'Successo',
			`Operatore ${toggle ? 'abilitato' : 'disabilitato'} correttamente`,
			'success',
		);
		return response;
	},
	assignGroups: async (groups, operatorId) => {
		await AdminService.assignGroups(groups, operatorId);
		await get().getPaginatedOperators({
			page: 0,
			value: '',
			size: DEFAULT_ITEMS_FOR_PAGE,
			enabled: get().showEnabled,
		});
		showNotification('Gruppi assegnati', 'Gruppi assegnati con successo', 'success');
	},
	getPaginatedOperatorsReport: async ({ page, value, size }) => {
		set({
			loadingOperator: true,
		});
		const response = await OperatorService.getPaginatedOperatorsReport({
			page,
			value: value || '',
			size: size || DEFAULT_ITEMS_FOR_PAGE,
		});
		set({
			loadingOperator: false,
			paginatedOperatorsReport: response.data,
		});
		return response.data;
	},
	setShowEnabled: (value) => {
		set({ showEnabled: value });
	},
}));
