import { useEffect, useRef } from 'react';
import { useUserStore } from './stores/useUserStore';
import axios from 'axios';

// const INACTIVITY_TIMEOUT = 3000; // Timeout di inattività in millisecondi (30 minuti)
const INACTIVITY_TIMEOUT = 1800000; // Timeout di inattività in millisecondi (30 minuti)

const LogoutAlert = () => {
	const [logout, user] = useUserStore((state) => [state.logout, state.user]);
	const inactivityTimerRef = useRef<NodeJS.Timeout | null>(null);
	const isActiveRef = useRef<boolean>(false); // Riferimento per tenere traccia dell'attività dell'utente

	// Funzione per inviare il ping
	const sendPing = async () => {
		try {
			if (user) {
				console.log('Invio ping al server...');
				await axios.post('/v1/api/ping');
				console.log('Ping inviato con successo.');
			}
		} catch (error) {
			console.error("Errore durante l'invio del ping:", error);
		}
	};

	useEffect(() => {
		if (user && user.role === 'OPERATOR') {
			const handleActivity = () => {
				if (inactivityTimerRef.current) {
					clearTimeout(inactivityTimerRef.current);
				}
				inactivityTimerRef.current = setTimeout(() => {
					console.log('Timeout di inattività raggiunto. Eseguo logout...');
					logout(); // Logout dopo il timeout di inattività
				}, INACTIVITY_TIMEOUT);

				isActiveRef.current = true; // L'utente è attivo
				console.log("Attività dell'utente rilevata.");
				sendPing(); // Invia il ping quando viene rilevata attività
			};

			// Funzione per gestire la visibilità della pagina
			const handleVisibilityChange = () => {
				if (document.visibilityState === 'visible') {
					console.log('La scheda è attiva.');
					isActiveRef.current = true; // La scheda è attiva
				} else {
					console.log('La scheda non è attiva.');
					isActiveRef.current = false; // La scheda non è attiva
				}
			};

			// Aggiungi eventi di attività dell'utente (solo click)
			const handleClick = () => {
				handleActivity(); // Rileva l'attività quando si fa clic
			};

			window.addEventListener('click', handleClick);
			document.addEventListener('visibilitychange', handleVisibilityChange);

			// Pulisci gli intervalli e gli eventi quando il componente si smonta
			return () => {
				if (inactivityTimerRef.current) {
					clearTimeout(inactivityTimerRef.current);
				}
				window.removeEventListener('click', handleClick);
				document.removeEventListener('visibilitychange', handleVisibilityChange);
			};
		}
	}, [logout, user]);

	return null;
};

export default LogoutAlert;
