import classNames from 'classnames';
import * as _ from 'lodash';
import PropTypes from 'prop-types';
import { ReactElement, forwardRef, useEffect, useLayoutEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { LOCAL_STORAGE_TOKEN_KEY } from '../../common/constants';
import { agencyPagesMenu, loginPagesMenu } from '../../menu';
import { useUserStore } from '../../stores/useUserStore';
import { IPageProps } from '../Page/Page';
import { ISubHeaderProps } from '../SubHeader/SubHeader';

interface IPageWrapperProps {
	role?: string[];
	isProtected?: boolean;
	title?: string;
	description?: string;
	children: ReactElement<ISubHeaderProps>[] | ReactElement<IPageProps> | ReactElement<IPageProps>[];
	className?: string;
}
const PageWrapper = forwardRef<HTMLDivElement, IPageWrapperProps>(
	({ role, isProtected, title, description, className, children }, ref) => {
		const user = useUserStore((state) => state.user);
		const logout = useUserStore((state) => state.logout);

		const navigate = useNavigate();

		const executeLogout = () => {
			logout();
			navigate(`../${loginPagesMenu.login.path}`);
		};

		useLayoutEffect(() => {
			// @ts-ignore
			document.getElementsByTagName('TITLE')[0].text = `${title ? `${title} | ` : ''}${
				process.env.REACT_APP_SITE_NAME
			}`;
			// @ts-ignore
			document
				?.querySelector('meta[name="description"]')
				.setAttribute('content', description || process.env.REACT_APP_META_DESC || '');
		});

		useEffect(() => {
			if (isProtected && role && user) {
				if (role && role.filter((r) => _.includes(user.role, r)).length === 0) {
					navigate(`../${agencyPagesMenu.agency.path}`);
				}
			} else if (isProtected && !user && !localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY)) {
				// Utente non autenticato, reindirizza alla pagina di login
				executeLogout();
			}
		}, []);

		return (
			<div ref={ref} className={classNames('page-wrapper', 'container-fluid', className)}>
				{children}
			</div>
		);
	},
);
PageWrapper.displayName = 'PageWrapper';
PageWrapper.propTypes = {
	isProtected: PropTypes.bool,
	title: PropTypes.string,
	description: PropTypes.string,
	// @ts-ignore
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
};
PageWrapper.defaultProps = {
	isProtected: true,
	title: undefined,
	description: undefined,
	className: undefined,
};

export default PageWrapper;
