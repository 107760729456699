import { useEffect } from 'react';
import { Badge, Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Button from '../../../components/bootstrap/Button';
import Popovers from '../../../components/bootstrap/Popovers';
import Icon from '../../../components/icon/Icon';
import Header, { HeaderLeft, HeaderRight } from '../../../layout/Header/Header';
import {
	messageUnreadPages,
	requestLeadPage,
	progressOperator,
	progressAgency,
	agencyTemplateSms,
	agencyPagesAppointment,
} from '../../../menu';
import { useAgencyStore } from '../../../stores/useAgencyStore';
import { useNotificationsStore } from '../../../stores/useNotificationsStore';
import { useOperatorStore } from '../../../stores/useOperatorStore';
import { useUserStore } from '../../../stores/useUserStore';

const DefaultHeader = () => {
	const navigate = useNavigate();
	const logout = useUserStore((state) => state.logout);
	const handleLogout = async () => {
		await logout();
		navigate('/login');
	};
	const [user] = useUserStore((state) => [state.user]);
	const [agency, getAgencyInformation] = useAgencyStore((state) => [
		state.agencyInformation,
		state.getAgencyInformation,
	]);

	const [operator, getOperatorInformation] = useOperatorStore((state) => [
		state.operatorInformation,
		state.getOperatorInformation,
	]);

	const [countNewNotifications, count] = useNotificationsStore((state) => [
		state.countNewNotifications,
		state.countNewNotification,
	]);

	useEffect(() => {
		if (user) countNewNotifications();
		if (user?.uuidAgency) getAgencyInformation(user.uuidAgency);
		if (user?.uuidOperator) getOperatorInformation(user.uuidOperator);
	}, [countNewNotifications]);

	const goToLeads = () => {
		navigate(`../homepage`);
	};
	const goToPratiche = () => {
		navigate(`../pratiche`);
	};
	const createLeads = () => {
		navigate(`../lead`);
	};

	const goToProgress = () => {
		navigate(`../${progressOperator.progress.path}`);
	};
	const goToReportAgency = () => {
		navigate(`../${progressAgency.progress.path}`);
	};
	const goToSupport = () => {
		navigate(`../support`);
	};

	const goToNotification = () => {
		navigate(`../${messageUnreadPages.message.path}`);
	};

	const goToRequestPage = () => {
		navigate(`../${requestLeadPage.requestLead.path}`);
	};

	const goToTemplateSms = () => {
		navigate(`../${agencyTemplateSms.templateSms.path}`);
	};

	const goToAppointment = () => {
		navigate(`../${agencyPagesAppointment.appointment.path}`);
	};
	const goToEsiti = () => {
		navigate(`../esiti`);
	};

	const goToSendSms = () => {
		navigate(`../massive-sms`);
	};

	const goChargeSms = () => {
		navigate(`../payment`);
	};

	const goUploadFileLeads = () => {
		navigate(`../upload-file`);
	};

	const goToFileUploaded = () => {
		navigate(`../file-uploaded`);
	};
	const goToTickets = () => {
		navigate(`../admin-support`);
	};

	const goToCollaborator = () => {
		navigate(`../collaborator`);
	};

	if (!user) {
		return <></>;
	} else
		return (
			<Header>
				<HeaderLeft>
					{user?.role === 'AGENCY' && (
						<div className='d-inline-flex'>
							<Dropdown>
								<Dropdown.Toggle variant='primary'>Lead</Dropdown.Toggle>
								<Dropdown.Menu>
									<Dropdown.Item onClick={goToLeads}>I miei leads</Dropdown.Item>
									<Dropdown.Item onClick={goUploadFileLeads}>Carica file leads</Dropdown.Item>
									<Dropdown.Item onClick={goToRequestPage}>Richiedi leads</Dropdown.Item>
									<Dropdown.Item onClick={goToFileUploaded}>File caricati</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						</div>
					)}
					{user?.role === 'COLLABORATOR' && (
						<div className='d-inline-flex'>
							<Button color='primary' onClick={goToLeads}>
								Leads
							</Button>
						</div>
					)}
					{(user?.role === 'AGENCY' || user?.role === 'COLLABORATOR') && (
						<div className='d-inline-flex'>
							<Button color='primary' onClick={goToAppointment}>
								Appuntamenti
							</Button>
						</div>
					)}
					{(user?.role === 'AGENCY' || user?.role === 'COLLABORATOR') && (
						<div className='d-inline-flex'>
							<Button color='primary' onClick={goToPratiche}>
								Pratiche
							</Button>
						</div>
					)}
					{(user?.role === 'AGENCY' || user?.role === 'COLLABORATOR') && (
						<div className='d-inline-flex'>
							<Dropdown>
								<Dropdown.Toggle variant='dark'>SMS</Dropdown.Toggle>
								<Dropdown.Menu>
									<Dropdown.Item onClick={goToSendSms}>Invia SMS Massivo</Dropdown.Item>
									<Dropdown.Item onClick={goToTemplateSms}>Template SMS</Dropdown.Item>
									{/* <Dropdown.Item onClick={goChargeSms}>Ricarica SMS</Dropdown.Item> */}
								</Dropdown.Menu>
							</Dropdown>
						</div>
					)}
					{user?.role === 'AGENCY' && (
						<div className='d-inline-flex'>
							<Button color='dark' onClick={goToEsiti}>
								Esiti
							</Button>
						</div>
					)}
					{user?.role === 'OPERATOR' && (
						<div className='d-inline-flex'>
							<Button color='primary' onClick={createLeads}>
								Crea leads
							</Button>
						</div>
					)}
					{user?.role === 'OPERATOR' && (
						<div className='d-inline-flex'>
							<Button color='primary' onClick={goToLeads}>
								I miei leads
							</Button>
						</div>
					)}
					{user?.role === 'OPERATOR' && (
						<div className='d-inline-flex'>
							<Button color='primary' onClick={goToProgress}>
								Report giornaliero
							</Button>
						</div>
					)}
					{user?.role === 'AGENCY' && (
						<div className='d-inline-flex'>
							<Button color='dark' onClick={goToReportAgency}>
								Report
							</Button>
						</div>
					)}
					{user?.role === 'AGENCY' && (
						<div className='d-inline-flex'>
							<Button color='dark' onClick={goToSupport}>
								Supporto
							</Button>
						</div>
					)}
					{user?.role === 'AGENCY' && (
						<div className='d-inline-flex'>
							<Button color='dark' onClick={goToCollaborator}>
								Collaboratori
							</Button>
						</div>
					)}

					<div className='d-inline-flex'>
						{user?.role === 'OPERATOR' &&
							operator &&
							operator.groups &&
							operator.groups.length > 0 && (
								<div>
									{operator.groups.map((group, index) => (
										<span key={group}>
											<Badge
												style={{ fontSize: 'larger' }}
												bg={index % 2 === 0 ? 'success' : 'info'}
												className='mr-2'>
												<Icon icon='LocalOffer'></Icon>
												{group}
											</Badge>
										</span>
									))}
								</div>
							)}
					</div>
				</HeaderLeft>
				<HeaderRight>
					{user?.role === 'ADMIN' && (
						<div className='d-inline-flex'>
							<Button color='primary' onClick={goToTickets}>
								Ticket
							</Button>
						</div>
					)}
					{(user?.role === 'AGENCY' || user?.role === 'ADMIN' || user?.role === 'OPERATOR') && (
						<div className='d-inline-flex'>
							<Button color='primary' onClick={goToNotification}>
								Notifiche {count !== 0 && <Badge bg='success'>{count}</Badge>}
							</Button>
						</div>
					)}
					<div className='d-inline-flex'>
						{user?.role === 'AGENCY' && (
							<Popovers trigger='hover' desc='Crediti disponibili'>
								<div className='mx-2'>
									<Icon icon='MonetizationOn' size={'2x'} />
									<b>{agency?.credits}</b>
								</div>
							</Popovers>
						)}
						{user?.role === 'AGENCY' && (
							<Popovers trigger='hover' desc='Crediti SMS disponibili'>
								<div className='mx-2'>
									<Icon icon='Phone' size={'2x'} />
									<b>{agency?.creditiSms?.toFixed(2)}</b>
								</div>
							</Popovers>
						)}
						<div>
							<Icon icon='Person' size={'2x'} />
							<b>
								{user?.role === 'OPERATOR'
									? operator?.username?.toUpperCase()
									: user?.username?.toUpperCase()}
							</b>
						</div>
					</div>
					<Button color='primary' onClick={handleLogout}>
						Logout <Icon icon='Logout' />
					</Button>
				</HeaderRight>
			</Header>
		);
};

export default DefaultHeader;
