import { useContext } from 'react';
import ThemeContext from '../../../contexts/themeContext';
import Aside, { AsideBody, AsideHead } from '../../../layout/Aside/Aside';
import Brand from '../../../layout/Brand/Brand';
import Navigation from '../../../layout/Navigation/Navigation';
import { dashboardPagesMenu } from '../../../menu';

const DefaultAside = () => {
	const { asideStatus, setAsideStatus } = useContext(ThemeContext);

	return (
		<Aside>
			<AsideHead>
				<Brand asideStatus={asideStatus} setAsideStatus={setAsideStatus} />
			</AsideHead>
			<AsideBody>
				<Navigation menu={dashboardPagesMenu} id='aside-dashboard' />
			</AsideBody>
		</Aside>
	);
};

export default DefaultAside;
